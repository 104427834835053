// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-work-adapt-js": () => import("./../../../src/pages/work/adapt.js" /* webpackChunkName: "component---src-pages-work-adapt-js" */),
  "component---src-pages-work-bhp-js": () => import("./../../../src/pages/work/bhp.js" /* webpackChunkName: "component---src-pages-work-bhp-js" */),
  "component---src-pages-work-c-res-js": () => import("./../../../src/pages/work/c-res.js" /* webpackChunkName: "component---src-pages-work-c-res-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-informia-js": () => import("./../../../src/pages/work/informia.js" /* webpackChunkName: "component---src-pages-work-informia-js" */),
  "component---src-pages-work-sawu-js": () => import("./../../../src/pages/work/sawu.js" /* webpackChunkName: "component---src-pages-work-sawu-js" */),
  "component---src-pages-work-south-32-js": () => import("./../../../src/pages/work/south32.js" /* webpackChunkName: "component---src-pages-work-south-32-js" */),
  "component---src-pages-work-weigh-it-js": () => import("./../../../src/pages/work/weighIt.js" /* webpackChunkName: "component---src-pages-work-weigh-it-js" */)
}

